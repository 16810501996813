import React, { useState } from 'react';
import './App.css';
import { FaTwitter, FaTelegramPlane, FaBars } from 'react-icons/fa';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleBuyNowClick = () => {
    window.location.href = 'https://jup.ag/swap/SOL-DVZrNS9fctrrDmhZUZAu6p63xU6d9cqYxRRhJbtJ4z8G';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="logo">$ROSS</div>
          <div className="social-icons">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://telegram.org" target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a>
            <button className="buy-now" onClick={handleBuyNowClick}>BUY NOW</button>
          </div>
          <div className="hamburger" onClick={toggleMenu}>
            <FaBars />
          </div>
        </div>
        {menuOpen && (
          <div className="mobile-menu">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://telegram.org" target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a>
            <button className="buy-now" onClick={handleBuyNowClick}>BUY NOW</button>
          </div>
        )}
      </header>
      <main>
        <div className="content">
          <div className="meme-content">
            <img src="/ross.jpg" alt="Ross" />
          </div>
          <div className="about-section">
            <h2>About</h2>
            <p>Ross Ulbricht has been in prison since 2013 as a first-time offender with all non-violent convictions. He was condemned to die in prison for creating an anonymous e-commerce website called Silk Road. An entrepreneur passionate about free markets and privacy, he was 26 when he made the site. He was never prosecuted for causing harm or bodily injury and no victim was named at trial. Users of Silk Road chose to exchange a variety of goods, both legal and illegal, including drugs (most commonly small amounts of cannabis). Prohibited was anything involuntary that could harm a third party. Ross was not convicted of selling drugs or illegal items himself, but was held responsible for what others sold on the site.</p>
          </div>
        </div>
        <div className="marquee">
          <span>$ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS</span>
        </div>
        <div className="tokenomics-section">
          <h3 className="tokenomics-title">TOKENOMICS</h3>
          <ul className="tokenomics-list">
            <li>CA: DVZrNS9fctrrDmhZUZAu6p63xU6d9cqYxRRhJbtJ4z8G</li>
            <li>NAME: Ross Ulbricht</li>
            <li>TICKER: $Ross</li>
            <li>LAUNCH ON PUMP.FUN (LP BURNED)</li>
            <li>0 TAX</li>
            <li>SUPPLY: 1B</li>
          </ul>
          <div className="button-container">
            <button className="buy-now" onClick={handleBuyNowClick}>BUY NOW</button>
          </div>
          <div id="dexscreener-embed"><iframe src="https://dexscreener.com/solana/48PkHAGgfcuRj1A4Nrzns22cAJZKPdqFGWtd38xv7QEc?embed=1&theme=dark"></iframe></div>
        </div>
        <div className="marquee">
          <span>$ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS</span>
        </div>

        <h3 className="supporting-ross-title">TRUMP IS ON OUR SIDE</h3>
        <div className="supporting-ross-image">
          <img src="https://imgur.com/lwnqzRN.png" alt="Supporting Ross" />
        </div>
        <blockquote className="twitter-tweet" data-theme="dark">
          <p lang="en" dir="ltr">JUST IN: 🇺🇸 Donald Trump vows to commute the sentence of Ross Ulbricht to time served if elected President. <br /><br />"He's already served 11 years. We're going to get him home." <a href="https://t.co/lRfK1JafZG">pic.twitter.com/lRfK1JafZG</a></p>&mdash; Bitcoin Magazine (@BitcoinMagazine) <a href="https://twitter.com/BitcoinMagazine/status/1794535395176366260?ref_src=twsrc%5Etfw">May 26, 2024</a>
        </blockquote>
        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

        <div className="marquee">
          <span>$ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS &nbsp;&nbsp;&nbsp;&nbsp; $ROSS</span>
        </div>

        <h3 className="help-section-title">SUPPORTING ROSS</h3>
        <div className="help-section">
          <div className="help-image">
            <img src="ross.webp" alt="Ross Ulbricht" className="help-image" />
          </div>
          <div className="help-content">
            <p>Ross Ulbricht was an idealistic entrepreneur who never intended harm. He deeply regrets his actions and has learned his lesson. If released tomorrow, he would never break the law again. Ross hopes for a second chance and dreams of starting a family with his fiancée and making positive contributions to society. Please join us in freeing Ross!</p>
            <a href="https://www.change.org/p/clemency-for-ross-ulbricht-condemned-to-die-in-prison-for-an-e-commerce-website" className="help-button" target="_blank" rel="noopener noreferrer">How You Can Help</a>
          </div>
        </div>
        <div className="help-team-message">
          <p>From the team:</p>
          <p>We are planning on creating a wallet for Ross to help with any funds necessary by allocating him a token allowance. We are in process of reaching out to his wife (through his Twitter) and other means.</p>
        </div>
      </main>
    </div>
  );
}

export default App;
